$club-price-currency-color: #1f1a49;

.discount-bubble {
  border-radius: 50%;
  background-color: #d93717;
  width: 1em;
  height: 1em;

  .discount-label {
    color: #ffffff;
  }
}

.prodpage-discount-bubble {
  border-radius: 50%;
  background-color: #d93717;
  width: 1em;
  height: 1em;

  .prodpage-discount-label {
    color: #ffffff;
  }
}

.recommendations {
  &-content {
    .image-container {
      .discount-bubble {
        .discount-label {
          color: #ffffff;
        }
      }
    }
  }
}

.fa-darkgrey-cart {
  filter: brightness(0) invert(1);
}

.campaign-filter {
  &__search-bar--wrapper {
    background-color: #f8b912;
  }
}

.responsive-form.product-list {
  .discount-ladder,
  .discount-benefit {
    position: absolute;
    top: 12px;
    left: 12px;
  }
}

.ladder-price-rectangle,
.benefit-price-rectangle,
.discount-ladder,
.discount-benefit {
  position: absolute;
  top: 12px;
  right: 12px;
  @media (max-width: $screen-sm-min) {
    left: 12px;
    right: auto;
  }
}

.club-price-discount {
  display: inline-flex;
  padding: 4px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  font-family: "Barlow Condensed", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: normal;
  border-radius: 4px;
  display: inline-block;
  background: $club-price-currency-color;
  color: #fff;
  text-align: center;

  &:nth-child(1) {
    @media (max-width: $screen-xs-max) {
      padding-top: 0;
      font-size: 16px;
    }
  }

  &:nth-child(2) {
    font-size: 21px;

    @media (max-width: $screen-xs-max) {
      margin-top: -5px;
      font-size: 18px;
    }
  }
}

div.priceFreight-flex > .product-price-container > div.product-price-dicount > span {
  background-color: $club-price-currency-color !important;
  color: white !important;
}
